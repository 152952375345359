.contact__container{
    width: fit-content;
    display: flex;
    justify-content: center;
    gap: 3rem;
}
.contact__container > a{
    width: 5rem;
    color: white;
    background:var(--color-primary);
    aspect-ratio: 1/1;
    display:grid;
    place-items: center;
    font-size: 2rem;
    border: 2px solid transparent;
    border-radius: var(--border-radius-1);
    -webkit-border-radius: var(--border-radius-1);
    -moz-border-radius: var(--border-radius-1);
    -ms-border-radius: var(--border-radius-1);
    -o-border-radius: var(--border-radius-1);
}
.contact__container :hover{
    background: transparent;
    border-color: var(--color-primary);
    color: var(--color-primary);
    transform: translateY(-0.3rem);
    -webkit-transform: translateY(-0.3rem);
    -moz-transform: translateY(-0.3rem);
    -ms-transform: translateY(-0.3rem);
    -o-transform: translateY(-0.3rem);
}
/* MEDIA QUERY PHONES */
@media screen and (max-width:600px) {
    .contact__container{
        width: 1rem;
    }
    
}
