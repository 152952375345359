/* SWIPPER CSS */
#tesitimonial{
    padding-bottom: 0;
}
.swiper {
    height: fit-content;
    padding-bottom: 12rem;
    padding-top:1rem;
 
  }


.swiper-slide {
    cursor: grab;
  }
.testimonial__client{
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1.5rem;
}
.testimonial__client-avatar img{
    width: 3.5rem;
    aspect-ratio: 1/1;
    border-radius:var(--border-radius-4);
    transition:var(--transition);
    background-color:bisque;
    -webkit-border-radius:var(--border-radius-4);
    -moz-border-radius:var(--border-radius-4);
    -ms-border-radius:var(--border-radius-4);
    -o-border-radius:var(--border-radius-4);
  
}

.swiper-pagination {
    margin-bottom: 8rem;
}


.swiper-pagination-bullet {
    background: var(--color-primary);
}