

/* THEME CHANGE USING REACT */
main.color-1 {--primary-hue:270}
main.color-2 {--primary-hue:110}
main.color-3 {--primary-hue:240}
main.color-4 {--primary-hue:325}
main.color-5 {--primary-hue:0}
main.color-6 {--primary-hue:55}

main.bg-2{
    --black-lightness:96%;
    --dark-lightness:60%;
    --light-lightness:12%;
    --white-lightness:6%;
    --color-primary: hsl(var(--primary-hue), 89%, 41%);
    --color-white: hsl(0, 5%, var(--white-lightness));
    --color-light: hsl(var(--primary-hue), 5%, var(--light-lightness));
    --color-black: hsl(var(--primary-hue), 5%, var(--black-lightness));
    --color-dark: hsl(var(--primary-hue), 5%, var(--dark-lightness));
}

main{ 
    color:var(--color-dark);
     --color-primary: hsl(var(--primary-hue),89%, 41%);}


*{
    margin:0;
    padding:0;
    border:0;
    outline:0;
    list-style:none;
    text-decoration: none;
    box-sizing: border-box;
}
:root{
    --primary-hue:270;
    --black-lightness: 15%;
    --dark-lightness: 44%;
    --light-lightness: 94%;
    --white-lightness: 100%;
    --color-primary: hsl(var(--primary-hue), 89%, 41%);
    --color-white: hsl(0, 5%, var(--white-lightness));
    --color-light: hsl(var(--primary-hue), 5%, var(--light-lightness));
    --color-black: hsl(var(--primary-hue), 5%, var(--black-lightness));
    --color-dark: hsl(var(--primary-hue), 5%, var(--dark-lightness));
    /* GENERAL CONTAINER VARIABLES */
    --container-width-lg: 76%;
    --container-width-md: 90%;
    /* BORDER RADIUS VARIABLES */
    --border-radius-1: 1.2rem;
    --border-radius-2: 0.8rem;
    --border-radius-3: 0.5rem;
    --border-radius-4: 50%;
    /* GENERAL TRANSITION VARIABLE */
    --transition: all 500ms ease;
}
html{
    scroll-behavior: smooth;
}
body{
    font-family: 'Montserrat', sans-serif;
    line-height:1.6;
    font-size: 0.9rem;
}
/* ALL CONTAINER CLASS */
.container{
    width: var(--container-width-lg);
    margin-inline: auto;
    max-width: 1920px;  
   
}
/* ALL SECTION STYLES */
section{
    padding:10rem 0;
    background: var(--color-light);
    transition: var(var(--transition));
}

section:nth-child(even){
    background: var(--color-white);
}

section >h2{
    text-align: center;
}
section >p{
    text-align: center;
    margin: 0.5rem auto 4rem;
    width: 40%;
}

/* ALL ANCHOR TAGS */
a {
    color:  var(--color-white) ;
    transition:var(--transition);
}
/* ALL HEADING STYLES */
h1,h2,h3,h4,h5,h6{
    line-height: 1.1;
    color:var(--color-black);
}

h1{font-size: 3rem;}
h2{font-size: 2rem;}
h3{font-size: 1.6rem;}
h4{font-size: 1.1rem;}
h5{font-size: 0.95rem;}
h6{font-size: 0.8rem;}

/* ALL IMAGE STYLES*/
img{
    display: block;
    width: 100%;
    object-fit: cover;
}
/* ALL BUTTON STYLES */
.btn{
    display:inline-block;
    width:fit-content;
    display: flex;
    align-items: center;
    gap: 1rem;
    background: var(--color-light);
    color: var(--color-black);
    padding:0.6rem 2.5rem;
    border: 2px solid transparent;
    border-radius: var(--border-radius-2);
}
.btn:hover{
    background-color: transparent;
    border-color:var(--color-light);
    transform:translateY(-0.5rem)
}
.btn.white{
    background: var(--color-white);
}
.btn.hover:hover{
    background: transparent;
    border-color: var(--color-white);
}
.btn.primary{
    background: var(--color-primary);
    color:white;
}
.btn.primary:hover{
    background: transparent;
    border-color: var(--color-primary);
    color: var(--color-primary)
}
.btn.sm{
    padding:.5rem 1.2rem;
}

/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 1024px) {
    body {font-size: 1rem;}
    .container{width:var(--container-width-md);}
    section {padding: 8rem 0;}
    section > p {
        width:72%;
        margin-bottom: 3rem;
    }
    h1{font-size: 2rem;}
}

/* MEDIA QUERIES (phones) */
@media screen and (max-width: 600px) {
    h1 {font-size: 2.4rem;}
    h2 {font-size: 1.8rem;}
    h3 {font-size: 1.4rem;}
    h4{font-size: 1rem;}
    .btn{padding:0.6rem 1.5rem}
    section > p { width: var(--container-width-md); }
}