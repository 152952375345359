.portfolio__categories{
    display:flex;
    justify-content: center;
    gap: 1.5rem;
    flex-wrap: wrap;
    
}
.btn.cat__btn{
    text-transform: capitalize;
    transition: var(__transition);
 
}
.btn.cat__btn:hover{
    transform: translateY(0);
}
.portfolio__projects {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    align-items: start;
}
.card.portfolio__project {
    animation: animateProject 700ms  ease-in forwards;
    -webkit-animation: animateProject 700ms  ease-in forwards;
}
@keyframes animateProject{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
.portfolio__projects-image{
    width: 100%;
    height: fit-content;
    min-height: 7rem;
    max-height: 15rem ;
    background: var(--color-light);

    border-radius: var(--border-radius-2);
    -webkit-border-radius: var(--border-radius-2);
    -moz-border-radius: var(--border-radius-2);
    -ms-border-radius: var(--border-radius-2);
    -o-border-radius: var(--border-radius-2);
    margin-bottom: 1.5rem;
    overflow: hidden;

}

.portfolio__project p{
    margin: 0.5rem 0 2rem;
}
.portfolio__project-cta{
    display: flex;
    gap: 1rem;
}

.portfolio__project:hover .btn.sm{
    border-color: (--color-white);
}

.portfolio__project-cta:hover .btn.sm.primary{
    background: transparent;
    color: var(--color-primary);
    border-color: var(--color-primary);
}

/* MEADIA QUEIES FORE TABLETS */
@media  screen and (max-width: 1024px) {
    .portfolio__projects{
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
    }
    
}

/* MEADIA QUEIES FORE PHONES */
@media  screen and (max-width: 600px) {
    .portfolio__categories{
        gap: 0.6rem;
    }
    .btn.cat__btn{
        padding: 0.7rem 0.8rem;
    }
    .portfolio__projects{
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    
}