#floating__nav{
    display: none;
}

/* MEDIA QUERY TAB */
@media screen and (max-width: 1024px){
    #floating__nav{
        display: flex;
        align-items: center;
        position: fixed;
        bottom: 2.5rem;
        left:50%;
        transform: translateX(-50%);
        z-index: 9;
    }
    .scrollspy{
        display:flex;
        gap:1rem;
        background: rgba(0,0,0,0.3);
        padding: 0.8rem 1.5rem;
        backdrop-filter: blur(20px);
        border-radius:  3rem;
        -webkit-border-radius:  3rem;
        -moz-border-radius:  3rem;
        -ms-border-radius:  3rem;
        -o-border-radius:  3rem;
    }

    #floating__nav a{
        display: flex;
        padding: 0.6rem;
        font-size: 1.3rem;
        color:white;
        animation: animate-fn var(--transition);
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        -webkit-animation: animate-fn 300ms ease forwards;
}

    @keyframes animate-fn {
        from{
            opacity: 0;
            bottom: -5rem;
        }
        to{
            opacity: 1;
            bottom: 3rem;
        }
    }

    #floating__nav li.active a{
        background: var(--color-primary);
    }
}
