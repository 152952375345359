.card.theme__modal{
    background: #dedede;
    position:fixed;
    top: 50%;
    left:50%;
    z-index: 200;
    text-align: center;
    box-shadow: 0 3rem 3rem rgba(0,0,0,0.2);
    padding: 3rem;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    opacity: 0;
    animation: animate-modal 500ms ease-in forwards;
    -webkit-animation: animate-modal 500ms ease-in forwards;
}
@keyframes animate-modal {
    to {
        opacity: 1;
    
    }
}

.card.theme__modal:hover{
background: #dedede;
}
.card.card.theme__modal small{
    margin-top: 0.6rem;
    display: block;
    width: 80%;
    margin-inline: auto
    ;
}
.theme__primary-wrapper{
    margin: 3rem;

}

.theme__primary-colors{
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    flex-wrap: wrap;
}
.theme__primary-colors button{
    width: 2rem;
    aspect-ratio: 1/1;
    cursor: pointer;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.theme__primary-colors button:hover{
    transform: translateY(-0.2rem);
    transition:var(--transition) ;
    -webkit-transition:var(--transition) ;
    -moz-transition:var(--transition) ;
    -ms-transition:var(--transition) ;
    -o-transition:var(--transition) ;
    -webkit-transform: translateY(-0.2rem);
    -moz-transform: translateY(-0.2rem);
    -ms-transform: translateY(-0.2rem);
    -o-transform: translateY(-0.2rem);
}


.theme__primary-colors button.color-1{background: hsl(270, 70%, 46%);}
.theme__primary-colors button.color-2{background: hsl(110, 70%, 46%);}
.theme__primary-colors button.color-3{background: hsl(240, 70%, 46%);}
.theme__primary-colors button.color-4{background: hsl(325, 70%, 46%);}
.theme__primary-colors button.color-5{background: hsl(0, 70%, 46%);}
.theme__primary-colors button.color-6{background: hsl(52, 70%, 46%);}

.theme__background-colors{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-top: 1rem;
}

.theme__background-colors button{
    width: 7rem;
    height: 2.5rem;
    background: white;
    cursor: pointer;
    
    border-radius: var(--border-radius-3);
    -webkit-border-radius: var(--border-radius-3);
    -moz-border-radius: var(--border-radius-3);
    -ms-border-radius: var(--border-radius-3);
    -o-border-radius: var(--border-radius-3);
}

.theme__background-colors button.bg-2{
    background:#111;
}

.theme__background-colors button:hover{
    transform: translateY(-0.1rem);
    transition:var(--transition) ;
    -webkit-transform: translateY(-0.1rem);
    -moz-transform: translateY(-0.1rem);
    -ms-transform: translateY(-0.1rem);
    -o-transform: translateY(-0.1rem);
}

/* MEDIA QUERY [PHONES] */
@media screen and (max-width:600px) {
    .card.theme__modal{
        width: var(--container-width-md);
        padding: 3rem 1rem;
    }
    .theme__primary-colors{
        gap: 0.6rem;
    }
}